<template>
	<v-sheet
		:style="{ zIndex: isMobile ? 6 : 3 }"
		:class="{ mobile: isMobile && filterActive }"
		class="rounded-0 filterPanel"
		:width="filterStatus"
		color="menu_background">
		<v-overlay
			color="menu_background"
			opacity="0.57"
			v-if="overlay"
			@click="() => toggleFilter()"
			z-index="0"
			:value="filterActive"></v-overlay>
		<div class="filterBtnWrap d-flex flex-column align-self-center">
			<v-tooltip
				v-if="!locked"
				left
				color="primary darken-2">
				<template v-slot:activator="{ on: tooltip }">
					<v-btn
						@click="() => toggleFilter()"
						class="primary elevation-0 filterBtn mb-1"
						x-small
						v-on="tooltip"
						fab>
						<v-icon
							size="22px"
							class="animated"
							:class="{ 'spin-me': filterActive }">
							mdi-chevron-left
						</v-icon>
					</v-btn>
				</template>
				<span class="white--text">
					{{ filterActive ? "Close" : "Open" }} filter
				</span>
			</v-tooltip>
			<v-tooltip
				v-if="refreshButton"
				left
				color="primary darken-2">
				<template v-slot:activator="{ on: tooltip }">
					<v-btn
						@click="() => $emit('refresh')"
						class="primary elevation-0 filterBtn my-1 pb-1"
						x-small
						v-on="tooltip"
						fab>
						<v-icon
							class="pt-1"
							size="22px">
							mdi-refresh
						</v-icon>
					</v-btn>
				</template>
				<span class="white--text">
					{{ this.refreshText }}
				</span>
			</v-tooltip>

			<v-tooltip
				v-if="filterButton"
				left
				:color="`${filterIsEmpty ? 'secondary darken-2' : 'success darken-2'}`">
				<template v-slot:activator="{ on: tooltip }">
					<v-btn
						@click="clearFilter"
						:disabled="loading"
						v-on="tooltip"
						class="elevation-0 filterBtn mt-1"
						:class="`${filterIsEmpty ? 'secondary' : 'success'}`"
						x-small
						fab>
						<v-icon
							class="justify-center"
							size="21px">
							mdi-eraser
						</v-icon>
					</v-btn>
				</template>
				<span class="white--text">
					{{ filterIsEmpty ? "Filter is empty" : "Clear filter" }}
				</span>
			</v-tooltip>
		</div>
		<v-card
			class="mt-0 pt-0 mb-0 pb-0 px-0 mx-4 filterContent spacer overflow-auto">
			<v-card-text class="overflow-hidden pt-0 mt-0 px-2">
				<slot
					class="pt-0 mt-0"
					ref="filter"
					name="default"></slot>
			</v-card-text>
		</v-card>
	</v-sheet>
</template>
<script>
import localstorage from "../../mixins/localstorage.js";
export default {
	mixins: [localstorage],
	name: "v-filter",
	props: {
		width: {
			type: String,
			default: "65vw",
		},
		filterIsEmpty: {
			type: Boolean,
			default: false,
		},
		filterIsActive: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		refreshButton: {
			type: Boolean,
			default: false,
		},
		refreshText: {
			type: String,
			default: "Refresh filter",
		},
		locked: {
			type: Boolean,
			default: false,
		},
		filterButton: {
			type: Boolean,
			default: true,
		},
		overlay: {
			type: Boolean,
			default: true,
		},
	},
	created() {
		this.$syncWithStorage("filterActive", this.$route.name);
		this.filterActive = this.$getFromStorage("filterActive") ?? false;
	},
	data() {
		return {
			filterActive: false,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		filterStatus() {
			return this.filterActive ? `${this.width}` : "20px";
		},
	},
	methods: {
		clearFilter() {
			if (this.filterIsEmpty) return;
			this.$emit("clear");
		},
		toggleFilter(state) {
			this.filterActive = state ?? !this.filterActive;
			if (this.filterActive) {
				this.$emit("opened");
			}
		},
	},
};
</script>

<style>
.filterPanel {
	top: 64px;
	height: calc(100% - 84px);
	z-index: 2;
	right: -7px;
	display: flex;
	position: fixed;
	transition: width 0.3s ease-in-out;
}

.mobile.filterPanel {
	top: 0px;
	height: 100%;
}

.mobile .filterBtnWrap {
	position: absolute;
	left: 0px;
	z-index: 8;
}

.mobile .filterBtn {
	outline: 0 !important;
	position: relative;
}

.filterBtnWrap {
	position: absolute;
	left: -20px;
	z-index: 1;
}

.filterBtn {
	outline: 8px solid var(--v-menu_background-base);
	position: relative;
}

.filterContent {
	display: inline-block;
	z-index: 2;
	padding: 20px;
}

.animated {
	transition: transform 0.3s ease-in-out;
}

.spin-me {
	transform: rotate(180deg);
}
</style>
